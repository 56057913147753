<template>
  <section class="inventory-add-wrapper">
    <!-- Title -->
    <b-row
      align-h="center"
      class="inventory-script-message"
    >
      <h2 class="title">
        {{ newSlot ? '지면 등록 신청이 완료되었으니, 이제 코드를 추가하세요.' : '등록하실 지면에 코드를 추가하세요.' }}
      </h2>
    </b-row>

    <!-- Message -->
    <b-row
      align-h="center"
      class="inventory-script-message"
    >
      <pre class="htmlcode">
        페이지의 &lt;body&gt;&lt;/body&gt; 태그 사이에 광고 단위 코드를 복사해서 붙여넣으세요.
        광고를 게재할 위치에 이 코드를 추가하세요. 이 작업을 모든 페이지에서 각 광고 단위에 대해 수행하세요.
      </pre>
    </b-row>

    <!-- Script -->
    <b-row
      align-h="center"
    >
      <b-form-textarea
        class="textarea-script"
        plaintext
        :value="script"
      />
    </b-row>

    <!-- Message -->
    <b-row
      align-h="center"
      class="inventory-script-message"
    >
      <p class="mb-2">
        코드 삽입 후 일반적으로 몇 분 이내로 광고가 페이지에 표시되지만, 경우에 따라 최대 1시간 이상이 소요될 수 있습니다.
      </p>
    </b-row>

    <!-- Button: Copy Script to clipboard -->
    <b-row
      align-h="center"
      class="inventory-script-copy"
    >
      <b-col
        cols="4"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2"
          block
          @click="copyToClipboard(script)"
        >
          코드 복사하기
        </b-button>
        <b-card-text
          v-if="!newSlot"
          class="text-center mt-2"
        >
          <b-link @click="goBack">
            <feather-icon icon="ChevronLeftIcon" />
            돌아가기
          </b-link>
        </b-card-text>
      </b-col>
    </b-row>
    <!-- Button: Complete process -->
    <b-row
      v-if="newSlot"
      align-h="center"
      class="inventory-add-complete"
    >
      <b-col
        cols="4"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2 mt-2"
          block
          @click="complete"
        >
          완료
        </b-button>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BRow, BCol, BFormTextarea, BCardText, BLink,
} from 'bootstrap-vue'
import useScriptCode from '@/views/utils/useScriptCode'
import { computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import { channelRouteNames } from '@/router/routes/channel'

export default {
  components: {
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BLink,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    newSlot: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { route, router } = useRouter()
    const { copyToClipboard, generateScript } = useScriptCode()
    const { channelId, slotId } = route.value.params
    const script = computed(() => generateScript(channelId, slotId))
    const goBack = () => router.go(-1)
    const complete = () => {
      router.replace({ name: channelRouteNames.slot, params: { channelId } })
    }

    return {
      script,
      copyToClipboard,
      goBack,
      complete,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select';
@import 'src/@core/scss/vue/libs/vue-flatpicker';
</style>
<style>
.title {
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 40px;
}

.htmlcode {
  text-align: center;
  font-size: medium;
}

.textarea-script {
  text-wrap: none;
  color: black;
  background-color: lightgrey;
  width: 60%;
  height: 300px;
  padding: 20px;
  margin-bottom: 40px;
}

</style>
